<template>
  <div class="minbox row-wrapper" @click="contextMenuData.show=false">
    <div class="menu">
      <div class="col-wrapper menuTitle">
        <div v-show="!isMenuExpand"
             class="col-wrapper">
          <!--          <span>乡土文化研学平台</span>-->
          <span>后台管理</span>
        </div>
        <Menu v-show="isMenuExpand"/>
      </div>
      <el-menu active-text-color="#ffffff"
               class="menuContain"
               text-color="#ffffff"
               :default-active="defaultTab"
               :collapse="isMenuExpand"
               :collapse-transition="true"
               @select="selectMenu"
               background-color="#20222A">
        <el-sub-menu class="subMenu"
                     index="发布">
          <template #title>
            <img class="icon"
                 src="../assets/static/menu/book.svg"
                 alt=""/>
            <span class="menuItem">
              发布
            </span>
          </template>
          <el-menu-item index="/publish">
            发布资源
          </el-menu-item>
          <el-menu-item index="/history">
            发布历史
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu class="subMenu" index="基础数据">
          <template #title>
            <img class="icon" src="../assets/static/menu/manageMent.svg" alt=""/>
            <span class="menuItem">
              基础数据
            </span>
          </template>
          <el-menu-item index="/category">类别</el-menu-item>
          <el-menu-item index="/base">基地</el-menu-item>
        </el-sub-menu>
        <el-sub-menu class="subMenu"
                     index="用户信息">
          <template #title>
            <img class="icon"
                 src="../assets/static/menu/person.svg"
                 alt=""/>
            <span class="menuItem">
              用户信息
            </span>
          </template>
          <el-menu-item index="/password">
            密码设置
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
    <div :style="containWidth" class="contain col-wrapper">
      <div class="header row-wrapper">
        <!--    左侧图标    -->
        <div class="row-wrapper">
          <div class="iconContent"
               v-show="isMenuExpand"
               @click="expandMenu">
            <Expand class="icon iconHover"/>
          </div>
          <div class="iconContent"
               v-show="!isMenuExpand"
               @click="expandMenu">
            <Fold class="icon iconHover"/>
          </div>
          <div @click="flushPage" class="iconContent">
            <RefreshLeft class="icon iconHover"/>
          </div>
          <div class="breadcrumb">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item v-for="item in breadCrumb"
                                  :key="item">
                <!--                <div style="width: 56px" class="col-wrapper">-->
                {{ getMenuItemTitle(item) }}
                <!--                </div>-->
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <div>
          <span class="title">乡土文化研学平台</span>
        </div>
        <!--    右侧图标    -->
        <div class="row-wrapper">
          <!--     打开/关闭全屏     -->
          <div class="iconContent">
            <div @click="fullScreen"
                 class="iconHover"
                 v-show="!isFullScreen">
              <img src="../assets/static/menu/fullScreen.svg"
                   class="icon"
                   alt="全屏"/>
            </div>
            <div @click="fullScreen"
                 class="iconHover"
                 v-show="isFullScreen">
              <img src="../assets/static/menu/cancelFullScreen.svg"
                   class="icon"
                   alt="全屏"/>
            </div>
          </div>
          <div>你好,{{ admin }}</div>
          <a class="logout" @click="logOut">
            退出
          </a>
        </div>
      </div>
      <div class="view">
        <el-tabs v-model="defaultTab"
                 @tab-remove="removeTab"
                 @tab-click="tabClick"
                 closable
                 type="card">
          <el-tab-pane v-for="item in editableTabs"
                       :label="item.title"
                       :key="item.name"
                       :name="item.name">
            <template #label>
              <span style="height: 100%" @contextmenu="e=>{onContextMenu(e,item)}">{{ item.title }}</span>
            </template>
          </el-tab-pane>
        </el-tabs>
        <router-view v-slot="{Component}">
          <keep-alive>
            <component :is="Component"/>
          </keep-alive>
        </router-view>
      </div>
    </div>
    <div :style="{left:contextMenuData.x+'px',top:contextMenuData.y+'px'}"
         v-show="contextMenuData.show"
         class="contextMenu col-wrapper">
      <div class="row-wrapper context-item" @click="closeOther">关闭其他</div>
      <div class="row-wrapper context-item" @click="closeRight">关闭右侧</div>
    </div>
  </div>
</template>

<script>
import '../styles/flax.css'

export default {
  name: "home",
  data() {
    return {
      isFullScreen: false,
      isTop: false, // 退出登录图标
      admin: "",
      // --- menu ---
      isMenuExpand: false,
      // --- tabs ---
      editableTabs: [],
      tabNames: [],
      breadCrumb: [],
      defaultTab: '/publish',
      //  --- 右键菜单 ---
      contextMenuData: {
        x: 0,
        y: 0,
        show: false
      },
      tabInfo: {}
    }
  },
  computed: {
    containWidth() {
      return {
        'width': String(window.screen.width - 210) + 'px'
      }
    }
  },
  methods: {
    /**
     * 获取tab的title
     * @param index
     * @returns {string}
     */
    getMenuItemTitle(index) {
      switch (index) {
        case '/publish':
          return '发布资源'
        case '/history':
          return '发布历史'
        case '/category':
          return '类别'
        case '/base':
          return '基地'
        case '/password':
          return '密码设置'
        case '/editArtical':
          return '文章修改'
        case '/student':
          return '学生管理'
        case '/parents':
          return '家长管理'
        case '/storage':
          return '入库/出库'
        case '/machineModel':
          return '型号管理'
        case '/bind':
          return '绑定关系'
        case '/returnManage':
          return '退换货管理'
        case '/inkStorge':
          return '笔芯入库/出库'
        case '/textbookChapters':
          return '教材章节'
        case '/itemPool':
          return '题库'
        default:
          return index
      }
    },
    // --- 事件 ---
    /**
     * 登出
     */
    logOut() {
      this.$store.commit("setToken", "")
      this.$router.push({path: '/login'})
    },
    /**
     * 刷新页面
     */
    flushPage() {
      location.reload()
    },
    /**
     * tab被点击
     * @param info
     */
    tabClick(info) {
      this.breadCrumb = this.editableTabs[info.index].indexPath
    },
    /**
     * 关闭右侧
     */
    closeRight() {
      this.editableTabs = this.editableTabs.slice(0, this.editableTabs.indexOf(this.tabInfo) + 1)
      this.tabNames = this.tabNames.slice(0, this.tabNames.indexOf(this.tabInfo.name) + 1)
      this.defaultTab = this.tabInfo.name
    },
    /**
     * 关闭其他
     */
    closeOther() {
      this.editableTabs = [this.tabInfo]
      this.tabNames = [this.tabInfo.name]
      this.defaultTab = this.tabInfo.name
    },
    /**
     * 打开右键菜单
     * @param e
     * @param info
     */
    onContextMenu(e, info) {
      e.preventDefault()
      this.contextMenuData.show = true
      this.contextMenuData.x = e.clientX
      this.contextMenuData.y = e.clientY
      this.tabInfo = info
    },
    /**
     * 展开/收起菜单
     */
    expandMenu() {
      this.isMenuExpand = !this.isMenuExpand
    },
    /**
     * 打开/关闭全屏
     */
    fullScreen() {
      this.isFullScreen = !this.isFullScreen
    },
    /**
     * 菜单激活
     * @param index
     * @param indexPath
     */
    selectMenu(index, indexPath) {
      this.breadCrumb = indexPath //面包屑
      this.defaultTab = index
      this.$store.commit('setIndexPath', indexPath)
      if (this.tabNames.indexOf(index) === -1) {
        this.editableTabs.push({
          name: index,
          title: this.getMenuItemTitle(index),
          indexPath
        })
        this.tabNames.push(index)
      }
    },
    /**
     * 关闭标签页
     * @param index
     */
    removeTab(index) {
      if (this.tabNames.length === 1) {
        return 1
      }
      let i = this.tabNames.indexOf(index)
      this.editableTabs.splice(i, 1)
      this.tabNames.splice(i, 1)
      if (this.defaultTab === index) {
        if (i === 0) {
          this.defaultTab = this.tabNames[0]
          return 1
        }
        this.defaultTab = this.tabNames[i - 1]
      }
    }
  },
  activated() {
    this.admin = this.$store.state.name
  },
  mounted() {
    this.defaultTab = this.$route.path
    this.editableTabs.push({
      title: this.getMenuItemTitle(this.defaultTab),
      name: this.defaultTab,
      indexPath: this.$store.state.indexPath
    })
    this.breadCrumb = this.$store.state.indexPath
    this.tabNames.push(this.defaultTab)
  },
  watch: {
    /**
     * 打开/关闭全屏
     * @param val
     */
    isFullScreen(val) {
      if (val) {
        document.documentElement.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    },
    /**
     * 路由跳转
     * @param val
     */
    defaultTab(val) {
      this.$router.push({path: val, query: this.$route.query})
    },
  },
}
</script>

<style lang="less" scoped>
.minbox {
  height: 100vh;

  .logout {
    margin: 0 10px;
    color: blue;
  }

  .logout:hover {
    cursor: pointer;
  }

  ::v-deep(.el-sub-menu:hover) {
    background-color: #009688;
  }

  ::v-deep(.el-menu-item:hover) {
    background-color: #009688;
  }

  ::v-deep(.el-menu-item.is-active ) {
    background-color: #017267;
  }

  ::v-deep(.el-sub-menu__title:hover ) {
    background-color: #009688 !important;
  }

  .menu {
    height: 100%;
    background-color: #20222A;
  }

  .menuContain {
    border: 1px solid #20222A;
  }

  .contain {
    justify-content: flex-start;
    height: 100%;
    //width: 100%;

    .header {
      width: 100%;
      height: 50px;
      justify-content: space-between;

      .breadcrumb {
        width: 155px;

        ::v-deep(.el-breadcrumb__inner) {
          font-size: 16px;
          font-weight: 600;
        }

        ::v-deep(.el-breadcrumb__separator) {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .title {
        font-size: 32px;
        color: #333333;
        font-weight: 700;
      }
    }
  }
}

.menuTitle {
  color: #ffffff;
  padding: 15px;

  div {
    width: 180px;
  }
}

.menuItem {
  margin-left: 15px;
}

.icon {
  width: 20px;
  height: 20px;
}

.iconHover:hover {
  cursor: pointer;
}

.iconContent {
  padding: 10px 15px;
  transition: all .5s;

  span {
    line-height: 24px;
  }

  .icon {
    margin-left: 5px;
  }
}

.iconContent:hover {
  border-bottom: 2px solid;
}

.popper {
  width: 80px;
}

.view {
  align-self: stretch;
  padding: 0 15px;
}

.contextMenu {
  background-color: #FFFFFF;
  border-radius: 4px;
  position: absolute;
  z-index: 100;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.context-item {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 15px;
  transition: all .5s;
}

.context-item:hover {
  cursor: pointer;
  background-color: #eee;
}
</style>